<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :storeModule="storeModule"
                    :dataTable="dataTable"
                    :labels="labels"
                  />
                  <Modal @close="closeModal" v-if="modal">
                    <template v-slot:body>
                      <qrcode-vue
                        class="qr"
                        :value="item.value || ''"
                        :size="240"
                        level="H"
                      />
                      <div class="qr-code">
                        <p class="qr-title w-242">{{ item.name }}</p>
                        <p class="qr-num">{{ item.value }}</p>
                      </div>
                    </template>
                    <template v-slot:footer>
                      <ul class="listGrid justify-content-end">
                        <li class="listGrid-item">
                          <button
                            class="btn btn-white"
                            type="button"
                            @click="closeModal"
                          >
                            閉じる
                          </button>
                        </li>
                      </ul>
                    </template>
                  </Modal>
                  <Modal @close="closeModal('cpmModal')" v-if="cpmModal">
                    <template v-slot:body>
                      <qrcode-vue
                        class="qr"
                        :value="item.cpmUrl || ''"
                        :size="240"
                        level="H"
                      />
                      <div class="qr-code">
                        <p class="qr-title w-242">{{ item.name }}</p>
                      </div>
                    </template>
                    <template v-slot:footer>
                      <ul class="listGrid justify-content-end">
                        <li class="listGrid-item">
                          <button
                            class="btn btn-white"
                            type="button"
                            @click="closeModal('cpmModal')"
                          >
                            閉じる
                          </button>
                        </li>
                      </ul>
                    </template>
                  </Modal>
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  v-if="$permission.isGroup()"
                  :modulePath="modulePath"
                  :subdomain="subdomain"
                  :listLength="qrCodeCount"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <!-- <button class="btn btn-red" type="button">削除</button> -->
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import QrcodeVue from 'qrcode.vue';
import modal from '@/mixins/plugin/modal';
import Modal from '@/components/Modal.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';

export default {
  name: 'ShopQr',
  data: function() {
    return {
      pageName: 'QRコード',
      labels: [{ key: 'name', name: 'タイトル' }, { isButtonColumn: true }],
      storeModule: 'qrCode',
      modulePath: 'qrCode/getQrCodeList',
      cpmModal: false
    };
  },
  components: {
    Modal,
    TableWithCheckbox,
    PaginationLog,
    QrcodeVue,
  },
  mixins: [modal],
  computed: {
    ...mapGetters({
      lists: 'qrCode/qrCodeList',
      qrCodeCount: 'qrCode/qrCodeCount',
      userInfo: 'auth/infor',
      isUseCpmParentEvent: 'event/isUseCpmParentEvent',
      subdomain: 'auth/commonSubdomain',
      isGmoOrOfficeLoginStoreAndStore: 'common/isGmoOrOfficeLoginStoreAndStore',
    }),
    dataTable() {
      return (
        this.lists &&
        this.lists.map((item) => {
          return {
            ...item,
            name: item.name,
            buttonLinks: [
              {
                content: 'QRコードを表示',
                class: 'btn btn-sm btn-bd-main',
                function: () => this.showModal(null, item),
              },
              {
                isNotShown: !this.isUseCpmParentEvent,
                content: 'QRCPMカード払い',
                class: 'btn btn-sm btn-bd-main',
                function: () => this.showModal('cpmModal', item),
              },
            ]
          };
        })
      );
    },
  },
  mounted() {
    if (this.isGmoOrOfficeLoginStoreAndStore)
      this.$store.dispatch('qrCode/getQrCodeList', {
        subdomain: this.subdomain,
      });
  },
};
</script>
